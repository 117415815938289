.header {
    display: flex;
    align-items: center;
}

.subHeader {
    display: flex;
}


.starRating {
    margin-top: 0.7rem;
}

.leftArrowIcon {
    position: absolute;
    margin-top: 5rem;
    margin-left: -1rem;
}

.leftArrowIcon:hover {
    background-color: gray;
}

.rightArrowIcon {
    position: absolute;
    margin-left: 63rem;
    margin-top: -4.5rem;
}

.rightArrowIcon:hover {
    background-color: gray;
}