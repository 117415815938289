.ag-theme-material {
    --ag-material-primary-color: #4B50DC;
    --ag-material-accent-color:#4B50DC;
}

div.ag-root {
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
}

.ag-row-hover {
    background-color: #6E6E6E20 !important;
}

/* .ag-cell-label-container {
    height: 100%;
}

.ag-header-cell-label {
    height: 100%;
    padding: 0 !important;
} */

.ag-theme-material .ag-row-selected {
    background-color: #6E6E6E20 !important;
}

.ag-header-cell-label .ag-header-cell-text {
    display: inline-block;
    font-size:14px;
    font-weight: normal;
    color: grey;
}

.ag-header-group-cell-label {
    justify-content: center !important;
}