.react-responsive-modal-container{
  padding-top: 50px;
}
.asin_performance_table .css-pwcg7p-MuiCollapse-root {
  display: none;
}
.campaign_performance_grid {
  width: 1120px !important;
}
.keyword_performance_grid {
  width: 1120px !important;
}
.searchTerm_performance_grid {
  width: 850px !important;
}
.target_performance_grid {
  width: 1140px !important;
}
.budget_performance_grid {
  width: 740px !important;
}
.reachTrend_performance_grid {
  width: 1200px !important;
}
.ams_campaign_grid {
  width: 1140px !important;
  margin: 0px !important
}
.ams_campaign_grid_item{
  padding: 0px!important
}
.js_product_grid {
  width: 1340px !important;
}
.bcgMatrixGrid {
  width: 1340px!important;
}
@media only screen and (max-width: 1550px) {
  .campaign_performance_grid {
    width: 1065px !important;
  }
  .keyword_performance_grid {
    width: 1065px !important;
  }
  .searchTerm_performance_grid {
    width: 850px !important;
  }
  .target_performance_grid {
    width: 1065px !important;
  }
  .budget_performance_grid {
    width: 740px !important;
  }
  .reachTrend_performance_grid {
    width: 1065px !important;
  }
  .ams_campaign_grid {
    width: 1140px !important;
    margin: 0px !important
  }
  .js_product_grid {
    width: 1194px !important;
  }
  .cohesity_logs_grid {
    width: 740px !important;
  }
  .bcgMatrixGrid {
    width: 1095px!important;
  }
}
@media only screen and (max-width: 1370px) {
  .campaign_performance_grid {
    width: 990px !important;
  }
  .keyword_performance_grid {
    width: 990px !important;
  }
  .searchTerm_performance_grid {
    width: 850px !important;
  }
  .target_performance_grid {
    width: 990px !important;
  }
  .budget_performance_grid {
    width: 740px !important;
  }
  .reachTrend_performance_grid {
    width: 990px !important;
  }
  .ams_campaign_grid {
    width: 990px !important;
    margin: 0px !important
  }
  .js_product_grid {
    width: 990px !important;
  } 
  .bcgMatrixGrid {
    width: 1026px!important;
  }
}
.highlightKeywords {
  background-color: unset;
  color: #0000FF;
}