.contribution-chart-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}

.contribution-chart-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.weekdays {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 26px;
}

.weekdays>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contribution-grid {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  padding-top: 8px;
}

.weekday {
  display: flex;
}

.day {
  padding: 8px;
  position: relative;
}

.day.active {
  background-color: #2c974b;
  color: #fff;
}

.day.inactive {
  background-color: gray;
  color: #fff;
}

.day {
  padding: 8px;
  position: relative;
  width: 15px;
  height: 15px;
  margin: 2px;
  border-radius: 4px;
}

.day.inactive {
  background-color: gray;
  color: #fff;
}

.count {
  display: none;
}

.last_element {
  display: none;
}

.day:hover .count {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px;
  border-radius: 4px;
  bottom: 20px;
  translate: -50%;
  z-index: 100;
}


.day:hover .last_element {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px;
  border-radius: 4px;
  bottom: 0px;
  right: 10px;
  top: 0px;
  z-index: 100;
}

.month-names {
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-columns: auto repeat(7, 1fr);
  grid-gap: 1px;
  background-color: #eee;
  font-family: Arial, sans-serif;
}

.grid-header {
  display: grid;
  grid-template-columns: auto repeat(7, 1fr);
}

.empty-cell {
  background-color: #eee;
}

.day-cell {
  background-color: #ccc;
  text-align: center;
  padding: 5px;
}

.month-row {
  display: grid;
  grid-template-columns: auto repeat(7, 1fr);
}

.month-cell {
  background-color: #ccc;
  text-align: center;
  padding: 5px;
}

.count-cell {
  background-color: #fff;
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
}

.month-names {
  display: flex;
  justify-content: space-between;
  width: 100%
}