.ag-theme-material {
    --ag-material-primary-color: #4B50DC;
    --ag-material-accent-color:#4B50DC;
}

div.ag-root {
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
}

.ag-row-hover {
    background-color: #6E6E6E20 !important;
}

.ag-theme-material .ag-row-selected {
    background-color: #6E6E6E20 !important;
}

.ag-header-cell-label .ag-header-cell-text {
    display: inline-block;
    font-size:14px;
    font-weight: normal;
    color: grey;
}

.ams-campaigns .ag-cell, .ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding: 0 10px;
}

.ams-campaigns .ag-header-cell, .ag-theme-material .ag-header-group-cell {
    padding: 0 10px;
}